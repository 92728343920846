<template>
  <div>
    <v-card class="mb-6">
      <v-card-title class="text-center">
        <h1 class="display-2 mb-2 font-weight-bold">Mes rapports</h1>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher par nom"
          single-line
          hide-details
        ></v-text-field>

        <v-data-table
          :headers="headers"
          :items="items"
          :multi-sort="true"
          :items-per-page="10"
          :footer-props="{
            'items-per-page-options': [5, 10, 25, 50, 100],
          }"
          class="elevation-1"
        >
          <template v-slot:item.url="{ item }">
            <router-link :to="item.url">
              {{ item.url }}
            </router-link>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";

export default {
  name: "GeneratedAlertsList",
  async created() {
    this.initial_items = await this.getReports();
    this.items = this.initial_items;
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nom",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Date", value: "date" },
      ],
      initial_items: [],
      items: [],
    };
  },
  methods: {
    async getReports() {
      const reportsData = await axios.get(
        "/alert-system-generated-reports/",
        {}
      );
      const reports = reportsData.data.reports;
      reports.map((report) => {
        report.url = `/alert-system/${report.id}/`;
      });
      return reportsData.data.reports;
    },
  },
  watch: {
    search(val) {
      val = val.toLowerCase();
      this.items = this.initial_items.filter((item) => {
        return item.name.toLowerCase().includes(val);
      });
    },
  },
};
</script>
